.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form {
  box-shadow: 10px 6px 12px rgba(0, 0, 0, 0.2);
}

.service-card {
  transition: box-shadow 0.3s ease;
}

.service-card:hover {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.2);
}

.custom-select {
  width: 100%;
  /* margin-bottom: 1rem; */
}

.select-label {
  display: block;
  /* margin-bottom: 0.5rem; */
}

.select {
  width: 100%;
  /* padding: 0.5rem 1rem; */
  font-size: 1rem;
  /* border: 1px solid #ced4da;
  border-radius: 0.25rem; */
  background-color: #fff;
  appearance: none; /* Remove default arrow */
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 1.5em auto;
}

/* Remove default arrow in Firefox */
.select::-ms-expand {
  display: none;
}

/* Hover and focus styles */
.select:hover,
.select:focus {
  border-color: #adb5bd;
  outline: none;
}


.tab {
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  /* background-color: #fff; */
  color: #333;
  cursor: pointer;
}

.tab-active {
  /* background-color: #038aae; */
  color: #fff;
  transition: .5s ease;
}

.tab-content {
  padding: 20px;
  height: 70%;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
}

/* Custom CSS to hide the scrollbars */
.companions-container::-webkit-scrollbar {
  width: 0.4rem; /* Set a width for the scrollbar (adjust as needed) */
}

.companions-container::-webkit-scrollbar-track {
  background-color: transparent; /* Make the track (background) transparent */
}

.companions-container::-webkit-scrollbar-thumb {
  background-color: transparent; /* Make the thumb (scrollbar handle) transparent */
  border: none;
}

/* Add a hover effect for the scrollbar thumb (optional) */
.companions-container::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.2); /* Add a slight hover effect */
}

