@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Add these styles to your global stylesheet or a relevant CSS file */
.tab-button {
  flex: 1;
  padding: 0.5rem;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tab-button.active {
  border-bottom-color: #038aae; /* Tailwind blue-500 */
  color: #038aae;
}

.tab-content {
  padding: 1rem 0;
}

.grid {
  display: grid;
  gap: 1.5rem;
}

.grid-cols-1 {
  grid-template-columns: 1fr;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-cols-3 {
  grid-template-columns: repeat(3, 1fr);
}

.hover\\:shadow-xl:hover {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -4px rgba(0, 0, 0, 0.1);
}


.calendar-wrapper {
  width: 100%;
  overflow-x: auto; /* Allow horizontal scrolling on small screens */
  white-space: nowrap;
}

.calendar-container {
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .calendar-container {
    width: 100%; /* Full width on mobile */
    min-width: 100%; /* Ensures it doesn’t shrink below 100% */
  }
}

@media only screen and (min-width: 769px) {
  .calendar-container {
    min-width: 1000px; /* Default width for larger screens */
  }
}